import { Edit, ExpandMore, Http, Image, PictureAsPdf, Search, TextSnippet, YouTube } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Badge, Button, Card, CardContent, Container, Dialog, DialogActions, DialogContent, Divider, InputAdornment, List, ListItem, TextField, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { Outlet, useNavigate } from "react-router";
import { Textfit } from "react-textfit";
import {ReactFitty} from "react-fitty";
import {useGlobal} from 'reactn';
import { FileDialog } from "./addfile";
import './filepage.css'
import { parseISO } from "date-fns";
import Vimeo from '@u-wave/react-vimeo'




export function Files() {
    const [data,setData] = useState([])
    const [axios,setAxios] = useGlobal('axios')
    const [lastlogin,setLastlogin] = useGlobal('lastlogin')
    const [showas,setShowas] = useGlobal('showas')
    const [isAdmin,setIsAdmin] = useGlobal('admin')
    const [modfile,setModfile] = useState(false)
    const [filter,setFilter] = useState('')
    const [folderdata,setFolderdata] = useState({})
    const [viewfolder,setViewfolder] = useState('Neuste')
    const [renameDialog,setRenameDialog] = useState(false)
    const [vimeo,setVimeo] = useState(false)
    const navigate = useNavigate()
    
    const getfiles = () => {
 
        axios.get('files').then(data => setData(data.data))
        axios.get('folderlist').then(data => {
            let tmp = data.data
           // tmp.unshift({'Neuste':[]})
            setFolderdata(tmp)
            })
    }


    const handleChange = (panel) => (event, isExpanded) => {
        setViewfolder(isExpanded ? panel : false);
      };
 
    useEffect(() =>{
        if (axios) getfiles()        
    },[axios])

    // useEffect(() => {
    //     if (vimeo) {
    //         if ('orientation' in window.screen && isMobile) {                
    //             window.screen.orientation.unlock().catch(err => {
    //                 console.error('Orientation unlock failed:',err)
    //             })
    //         }
    //     } else {
    //         if ('orientation' in window.screen && isMobile) {
    //             window.screen.orientation.lock('portrait').catch(err => {
    //                 console.error('Orientation lock failed:', err);
    //             })
    //         }  
    //     }
    // },[vimeo])


    const downloadfile = (id,mimetype,clickedlink) => {    
        if (mimetype == 'link' && clickedlink.includes('vimeo')) {
            setVimeo(clickedlink)
        } else {
            let link = document.createElement('a')
            if (mimetype == 'link') 
                link.href = clickedlink
            else
                link.href = 'https://notarzt-heinsberg.de/api/file/'+id;
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
        }
    }

    const closeRenameDialog = (result) => {
        setRenameDialog(false)
        if (result) {
            getfiles()
        }
    }


    const closefileDialog = (result) => {
        setModfile(false)
        if (result) {
            getfiles()
        }
    }


    const filetypeicon = (mimetype,text) => {
        if (mimetype == 'application/pdf')
            return <PictureAsPdf/>
        if (mimetype.includes('video'))
            return <YouTube/>
        if (mimetype == 'link') {
            if (text.includes('vimeo')) {
                return <YouTube/>
            } else {
                return <Http/>
            }
        }
        return  <TextSnippet/>
    }


    function OneAccordion({foldername,key}) {
        return (
            <Accordion expanded={viewfolder === foldername} key={'acc_'+key} className='fullfolder' onChange={handleChange(foldername)} onDoubleClick={e => setRenameDialog(foldername)}>
                <AccordionSummary expandIcon={<ExpandMore />} >
                    <Typography>{foldername +' ('+data.filter(f => !filter || f.filename.toLowerCase().includes(filter) || f.beschreibung.toLowerCase().includes(filter)).filter(f => f.folder.includes(foldername)).length+')'}{isAdmin && (foldername !== 'Neuste') && <Edit style={{color:'grey','verticalAlign':'sub'}} onClick={e => setRenameDialog(foldername)}/>}</Typography>
                </AccordionSummary>
                <AccordionDetails className='onefolder'> 
                {(foldername !== 'Neuste' && folderdata && folderdata[foldername].length > 0) ? 
                    <>                    
                    {data.filter(f => !filter || f.filename.toLowerCase().includes(filter) || f.beschreibung.toLowerCase().includes(filter)).filter(f => f.folder.replace('/','') === foldername).map((f,i) => 
                       <OneFile f={f} key={i}/>                      
                    )}
                    <Divider/>
                    {folderdata[foldername].map((subfolder,i) => 
                        <>
                        <Container>
                            <Typography variant='h6'>{subfolder}</Typography>
                            {data.filter(f => !filter || f.filename.toLowerCase().includes(filter) || f.beschreibung.toLowerCase().includes(filter)).filter(f => f.folder === foldername+'/'+subfolder).map((f,i) => 
                                <OneFile f={f} key={i}/>
                                )}
                        <Divider/>
                        </Container>
                        </>
                    )}
                    </>
                :
                    data.filter(f => !filter || f.filename.toLowerCase().includes(filter) || f.beschreibung.toLowerCase().includes(filter)).filter(f => f.folder.replace('/','') === foldername).map((f,i) => 
                       <OneFile f={f} key={i}/>
                    )
                }
                </AccordionDetails>
            </Accordion>
        )
    }

    function OneFile({f,key}) {
        const [lastlogin,setLastlogin] = useGlobal('lastlogin')


        return (
            <Tooltip key={'onefile_'+key} title={f.mimetype == 'link' ? f.link : f.beschreibung}> 
                    <Badge invisible={ parseISO(lastlogin.lastlogin) > (parseISO(f.uploadDate) || new Date()) } sx={{ maxWidth: isMobile ? '40%' : 160, height: isMobile ? 150 : 160, margin: '5px',padding:'5px' }}
                    variant="dot" color='error'  overlap="circular" anchorOrigin={{vertical: 'top',horizontal: 'left',}}>
                        <Box   className='onefile' 
                                onClick={e => {(isAdmin && !showas) ? setModfile(f) : downloadfile(f._id,f.mimetype,f.link)}}
                                >
                                <Box>
                                  {filetypeicon(f.mimetype,f.link)}
                                </Box>
                            <Typography sx={{ fontSize: 12 }} color="text.secondary">{f.uploadDate}</Typography>
                                {f.mimetype == 'link' ? 
                                    <Typography sx={{textAlign:'center',fontSize: isMobile ? 'small' : 'unset'}}>
                                       {f.beschreibung}
                                    </Typography>
                                :
                                <Typography sx={{textAlign:'center',fontSize: isMobile ? 'small' : 'unset'}}>
                                        {f.beschreibung || f.filename.replaceAll('_',' ').replaceAll('-',' ').replaceAll('+',' + ').replaceAll('.',' .')}
                                </Typography>
                                }
                        </Box>
                    </Badge>
            </Tooltip>
                    
        )
    }
     

 
    
    return (
        <Container fixed maxWidth='lg' className="fileblog">

            <div className='firstrow'>
            <TextField type='search' className='searchfield' fullWidth value={filter} onChange={e => setFilter(e.target.value.toLowerCase())} size="small" InputProps={{endAdornment: <InputAdornment position="end"><Search/></InputAdornment>,}}/>
            {isAdmin && !showas && <Button onClick={e => setModfile('new')} variant="contained">hochladen</Button>}
            </div>
            {folderdata && <OneAccordion foldername={'Neuste'} key={'neuste'}/>}
            {Object.keys(folderdata).map((foldername,i) => 
            <OneAccordion foldername={foldername} key={i}/>
            )}
            {isAdmin && !showas && <FileDialog modfile={modfile} close={e => closefileDialog(e)}/>}
            {isAdmin && !showas && <RenameFolderDialog folder={renameDialog} close={e => closeRenameDialog(e)} />}
            <Outlet/>
        <Dialog open={vimeo} fullWidth maxWidth="lg" onClose={e => setVimeo(false)}>
            <Vimeo video={vimeo} responsive={true} height={'100%'}/>
        </Dialog>
        </Container> 
    )

}



function RenameFolderDialog ({folder,close}) {
    const [old,setOld] = useState('')
    const [newname,setNewname] = useState('')
    const [axios,setAxios] = useGlobal('axios')


    useEffect(() => {
        setOld(folder)
        setNewname(folder)
    },[folder])


    const renameFolder = ()  => {
        if (old !== newname) {
            axios.post('renamefolder',{old:old,new:newname}).then(ret => close(true))
        } else {
            close(false)
        }
    }


    return (
        <Dialog open={folder} onClose={e => close(false)}>
            <DialogContent>
                <TextField value={newname} onChange={e => setNewname(e.target.value)} label='Ordner-Name'/>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={e => close(false)}>abbrechen</Button>
                <Button disabled={old === newname} variant='contained' onClick={e => renameFolder()}>ändern</Button>
            </DialogActions>
        </Dialog>

    )
}